import styled from "styled-components";

// #####################################################

export const StyledTooltipBox = styled.div`
	text-align: center;
	background-color: ${({ theme: { colors, isDarkMode }, allwaysLight }) =>
		isDarkMode && !allwaysLight ? colors.black : "#000"};
	opacity: 0.9;
	border: 1px solid black;
	color: ${({ theme, allwaysLight }) =>
		allwaysLight ? theme.colors.whiteTrueAlways : theme.colors.whiteTrue};
	font-size: 12px;
	padding: 3px 12px;
	border-radius: ${({ theme: { borderRadius } }) => borderRadius.element};
	transform-origin: center center;
	z-index: 100000;
	max-width: 250px;
	min-width: 200px;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	-webkit-animation: fadeIn 0.1s;
	-moz-animation: fadeIn 0.1s;
	-ms-animation: fadeIn 0.1s;
	-o-animation: fadeIn 0.1s;
	animation: fadeIn 0.1s;
`;
// #####################################################
