// ** Polyfills
import ResizeObserver from "resize-observer-polyfill";

// ** React Imports
import { useEffect, useState } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useLayer, Arrow } from "react-laag";

// ** Utils & Helpers
import { publish, subscribe, unsubscribe } from "helpers/events";

// ** Styled Components
import { StyledTooltipBox } from "./styles/TooltipBox.styled";

// #####################################################

const Tooltip = ({
	children,
	text,
	placement,
	allwaysLight,
	wrapperStyle,
	triggerOffset = 12,
	closeOnClickOutside = true,
}) => {
	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);
	const [isShow, setShow] = useState(false);

	const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
		onParentClose: () => setShow(false),
		onOutsideClick: () => {
			closeOnClickOutside && setShow(false);
		},
		isOpen: isShow,
		auto: true,
		overflowContainer: true,
		placement: "top-center",
		triggerOffset,
		container: "app",
		ResizeObserver,
		...placement,
	});

	useEffect(() => {
		subscribe("closeTooltips", () => {
			setShow(false);
		});

		subscribe("scroll", () => {
			setShow(false);
		});

		return () => {
			unsubscribe("closeTooltips");
			unsubscribe("scroll");
		};
	}, []);

	// #####################################################

	if ((text && text.length > 0) || typeof text === "object") {
		return (
			<>
				<div
					ref={triggerProps.ref}
					style={{ display: "inline-block", ...wrapperStyle }}
					onPointerEnter={() => {
						setShow(true);
					}}
					onPointerLeave={() => {
						publish("closeTooltips");
					}}
					onTouchStart={() => {
						publish("closeTooltips");
					}}
					onTouchEnd={() => {
						setShow(true);
					}}
					onTouchMove={() => {
						publish("closeTooltips");
					}}
				>
					{children}
				</div>

				{renderLayer(
					isShow ? (
						<StyledTooltipBox
							allwaysLight={allwaysLight}
							className="tooltip-box"
							{...layerProps}
						>
							{text}
							<Arrow
								{...arrowProps}
								backgroundColor={
									isDarkMode && !allwaysLight
										? "#f2f2f2"
										: "#000"
								}
								size={6}
							/>
						</StyledTooltipBox>
					) : null
				)}
			</>
		);
	}

	return children || null;
};

// #####################################################

export default Tooltip;

// #####################################################
